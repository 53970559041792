import React from 'react';
import '../styles.css';

function About() {
  return (
    <div className="section" id="about">
       <h1>Mike DeLorenze</h1>
      <p style={{ textAlign: 'center' }}>IT Professional with 19 Years of Experience</p>
      <h2>About Me</h2>
      <p>Hello! My name is Mike DeLorenze. I am an IT professional with 19 years of experience. I have extensive knowledge in system administration, network management, and IT support. I am passionate about technology and always eager to learn new skills and improve existing ones.</p>
    </div>
  );
}

export default About;
