import React from 'react';
import Contact from './Contact';
import '../styles.css';

const Content = () => {
  return (
    <div className="container">
      <div className="section" id="about">
        <h1>Access Management</h1>
        <p style={{ textAlign: 'center' }}>Extensive Knowledge Managing Access Security Groups and Applications</p>
        <h2>About</h2>
        <p>
          With extensive experience in access management, I specialize in securing and managing access to critical systems and data. My focus is on implementing robust access controls and identity management solutions to ensure that only authorized individuals have access to sensitive information.
        </p>
      </div>
      <div className="section" id="services">
        <h2>Services</h2>
        <ul>
          <li>Identity and Access Management (IAM)</li>
          <li>Role-Based Access Control (RBAC)</li>
          <li>Multi-Factor Authentication (MFA)</li>
          <li>Single Sign-On (SSO) Implementation</li>
          <li>Access Policy Development</li>
          <li>Privileged Access Management (PAM)</li>
          <li>Compliance and Audit Readiness</li>
          <li>User Lifecycle Management</li>
        </ul>

      </div>
      <Contact />
    </div>
  );
}

export default Content;
