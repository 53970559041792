import React from 'react';
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";


function Header() {
  return (
    <nav>
      <Link to="/">Home</Link>
      <Link to="/access-management">Access Management</Link>
      <Link to="/sys-admin">System Administrator</Link>
      <Link to="/power-shell">PowerShell</Link>
      <Link to="/net-mgmt">Network Management</Link>
      <Link to="/cloud-services">Cloud Services</Link>
      <Link to="/it-support">IT Support</Link>
    </nav>
  );
}

export default Header;
