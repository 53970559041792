import React from 'react';
import Contact from './Contact';
import '../styles.css';
const Content = () => {
  return (
    <div className="container">
      <div className="section" id="about">
        <h1>PowerShell Scripting</h1>
        <p style={{ textAlign: 'center' }}>I'm passionate about PowerShell Scripting and Automation for Mundane Tasks.</p>
        <h2>About</h2>
        <p>
          With extensive experience in PowerShell scripting, I specialize in automation and task management to streamline IT operations. My skills ensure efficiency and accuracy in managing systems and processes.
        </p>
      </div>
      <div className="section" id="services">
        <h2>Services</h2>
        <ul>
          <li>PowerShell Scripting</li>
          <li>Automation of Routine Tasks</li>
          <li>System Administration via PowerShell</li>
          <li>Custom Script Development</li>
          <li>Integration with Other Tools and Systems</li>
          <li>PowerShell Training and Support</li>
          <li>Security and Compliance Automation</li>
        </ul>
      </div>
      <Contact />
    </div>
  );
}

export default Content;
