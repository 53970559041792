import React, { useState } from 'react';

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = (e) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }

  return (
    <div className="section" id="contact">
      <h2>Contact</h2>
      <p>If you would like to get in touch with me, please feel free, click my name to send an email <a href="mailto:mike@delorenze.net" onClick={(e) => onClick(e)}>Mike</a>.</p>
      {isLoading && <p>Loading email...</p>}
    </div>
  );
}

export default Contact;