import React from 'react';
import Contact from './Contact';
import '../styles.css';

const Content = () => {
  return (
    <div className="container">
      <div className="section" id="about">
        <h1>Network Management</h1>
        <p style={{ textAlign: 'center' }}>12 Years of Excellence in Network Management and Seamless IT Solutions</p>
        <h2>About</h2>
        <p>
          With 12 years of hands-on experience in production environments, I specialize in managing and optimizing network systems. My expertise ensures robust, efficient, and secure network operations.
        </p>
      </div>
      <div className="section" id="services">
        <h2>Experience</h2>
        
        <ul>
          <li>Cisco ISE</li>
          <li>Cisco FirePower</li>
          <li>Cisco Umbrella</li>
          <li>DHCP</li>
          <li>DNS</li>
          <li>Microsoft IIS</li>
          <li>vSphere vSwitches</li>
        </ul>
      </div>
    <Contact />
    </div>
  );
}

export default Content;
