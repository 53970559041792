import React from 'react';
import Contact from './Contact';
import '../styles.css';

const Content = () => {
  return (
    <div className="container">
      <div className="section" id="about">
           <h1>IT Support</h1>
      <p style={{ textAlign: 'center' }}>IT Professional with 19+ Years of Experience in IT Support</p>
        <h2>About</h2>
        <p>
          With 19 years of experience in IT support, I am dedicated to ensuring your systems run smoothly and efficiently. I specialize in resolving technical issues promptly and effectively to minimize downtime and maximize productivity.
        </p>
      </div>
      <div className="section" id="services">
        <h2>Services</h2>
        <ul>
          <li>Remote IT Support</li>
          <li>Onsite IT Support</li>
          <li>Network Setup and Maintenance</li>
          <li>Hardware and Software Troubleshooting</li>
          <li>System Upgrades and Installations</li>
          <li>Data Backup and Recovery</li>
          <li>Security Solutions</li>
          <li>Cloud Services</li>
        </ul>
      </div>
      <Contact />
    </div>
  );
}

export default Content;
