import React from 'react';

function Skills() {
  return (
    <div className="section" id="skills">
      <h2>Skills</h2>
      <ul>
        <li>System Administration</li>
        <li>Network Management</li>
        <li>IT Support</li>
        <li>PowerShell</li>
        <li>Security Management</li>
        <li>Cloud Services</li>
      </ul>
    </div>
  );
}

export default Skills;
