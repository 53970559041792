import { Outlet, Link } from "react-router-dom";
import Header from "./Header"
import Body from './Body';
import Footer from "./Footer"

const Layout = () => {
  return (
    <>
        <Header />
          <Body />
        <Footer />
    </>
  )
};

export default Layout;