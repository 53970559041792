import React from 'react';
import About from './About';
import Skills from './Skills';
import Contact from './Contact';

const Content = () => {
  return (
    <div className="container">
    <About />
    <Skills />
    <Contact />
  </div>
  );
}

export default Content;