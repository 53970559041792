import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import AccessManagement from "./AccessManagement"
import SysAdmin from "./SysAdmin"
import PowerShell from "./PowerShell"
import Home from "./Home"
import NetworkManagement from "./NetworkManagement"
import CloudServices from "./CloudServices"
import ITSupport from "./ITSupport"
import Layout from "./Layout"

function Body() {
  return (
    <>
        <Outlet />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/access-management" element={<AccessManagement />} />
            <Route path="/sys-admin" element={<SysAdmin />} />
            <Route path="/power-shell" element={<PowerShell />} />
            <Route path="/net-mgmt" element={<NetworkManagement />} />
            <Route path="/cloud-services" element={<CloudServices />} />
            <Route path="/it-support" element={<ITSupport />} />
        </Routes>
      </>
  );
}

export default Body;
