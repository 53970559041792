import React from 'react';
import Contact from './Contact';
import '../styles.css';

const Content = () => {
  return (
    <div className="container">
      <div className="section" id="about">
        <h1>Cloud Services Management</h1>
      <p style={{ textAlign: 'center' }}>Extensive Knowledge and Experience in Cloud-based Services </p>
        <h2>About</h2>
        <p>
          With extensive experience in cloud services, I offer comprehensive solutions to help your organization leverage the power of the cloud. From migration to management, my expertise ensures seamless and efficient cloud operations.
        </p>
      </div>
      <div className="section" id="services">
        <h2>Services</h2>
        <ul>
          <li>Cloud Strategy and Planning</li>
          <li>Cloud Migration</li>
          <li>Cloud Infrastructure Management</li>
          <li>Cloud Security and Compliance</li>
          <li>Data Backup and Disaster Recovery</li>
          <li>Cloud Cost Optimization</li>
          <li>Hybrid Cloud Solutions</li>
          <li>Cloud Automation</li>
        </ul>
      </div>
      <Contact />
    </div>
  );
}

export default Content;
