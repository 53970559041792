import React from 'react';
import Contact from './Contact';
import '../styles.css';
const Content = () => {
  return (
    <div className="container">
      <div className="section" id="about">
        <h1>Systems Administration</h1>
      <p style={{ textAlign: 'center' }}>10 Years of Excellence in Efficient IT Solutions and Seamless Systems</p>
        <h2>About</h2>
        <p>
          With 10 years of experience in system administration, I specialize in managing and maintaining IT systems to ensure their optimal performance. My expertise covers a wide range of technologies and platforms, ensuring your IT infrastructure is reliable and secure.
        </p>
      </div>
      <div className="section" id="services">
        <h2>Services</h2>
        <ul>
          <li>Server Management</li>
          <li>Network Administration</li>
          <li>Virtualization (VMWare, Hyper-V)</li>
          <li>Backup and Recovery Solutions</li>
          <li>System Monitoring and Performance Tuning</li>
          <li>Security and Patch Management</li>
          <li>Cloud Integration and Management</li>
          <li>PowerShell Automation</li>
        </ul>
      </div>
      <Contact />
    </div>
  );
}

export default Content;
